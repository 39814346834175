<template>
  <div class="page-modal">
    <!--        我的信息-->
    <div class="my-info">
      <div class="picture">
        <img :src="info.photo_wall" alt="">
      </div>
      <div class="user">
        <div class="pic">
          <img src="@/assets/images/tx_pic.jpeg" alt="">
        </div>
        <div class="nickname">
          <span>{{info.nickname}}</span>
        </div>
        <div class="desc">
          <span>{{info.introduce}}</span>
        </div>
      </div>
      <div class="site-info">
        <div class="info">
          <div class="num">
            <span>{{info.articleNum}}</span>
          </div>
          <div class="title">
            <span>文章</span>
          </div>
        </div>
        <div class="info">
          <div class="num">
            <span>{{info.leaveNum}}</span>
          </div>
          <div class="title">
            <span>评论</span>
          </div>
        </div>
        <div class="info">
          <div class="num">
            <span>{{info.browseNum}}</span>
          </div>
          <div class="title">
            <span>浏览</span>
          </div>
        </div>
      </div>
    </div>
    <!--        日历-->
    <div class="calendar-info">
      <div class="date">
        <span>已运行{{runInfo.day}}天{{runInfo.hour}}小时{{runInfo.min}}分钟{{runInfo.sec}}秒</span>
      </div>
    </div>
    <!--        文章信息-->
    <div class="article-info">
      <el-tabs type="card" v-model="sortStatus" @tab-click="onArticleTab">
        <el-tab-pane label="最近更新" :name="0">
          <div class="article-list">
            <div class="list" v-for="(item,index) in articleList" :key="index">
              <div class="key">
                <span :class="{'action':index <= 2}">{{ index + 1 }}.</span>
              </div>
              <div class="title">
                <span>{{item.title}}</span>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="热门文章" :name="1">
          <div class="article-list">
            <div class="list" v-for="(item,index) in articleList" :key="index">
              <div class="key">
                <span :class="{'action':index <= 2}">{{ index + 1 }}.</span>
              </div>
              <div class="title">
                <span>{{item.title}}</span>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="推荐文章" :name="2">
          <div class="article-list">
            <div class="list" v-for="(item,index) in articleList" :key="index">
              <div class="key">
                <span :class="{'action':index <= 2}">{{ index + 1 }}.</span>
              </div>
              <div class="title">
                <span>{{item.title}}</span>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!--        最新留言-->
    <div class="leave-word">
      <div class="title-info">
        <div class="title">
          <span>最新留言</span>
        </div>
      </div>
      <div class="word-list">
        <div class="item" v-for="(item,index) in info.leaveList" :key="index">
          <div class="user-date">
            <div class="user">
              <div class="pic">
                <img :src="item.pic" alt="">
              </div>
              <div class="name">
                <span>{{item.nickname}}</span>
              </div>
            </div>
            <div class="date">
              <span>{{parseTime(item.create_time,'{y}年{m}月{d}日')}}</span>
            </div>
          </div>
          <div class="content">
            <span class="tag"></span>
            <span>{{item.content}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getCurrentInstance, onMounted, reactive, toRefs} from "vue";
import {ArticleFiltrate, RightData} from "../../api/api";

export default {
  name: "PageRight",
  setup() {
    let { proxy } = getCurrentInstance();
    const data = reactive({
      parseTime: '',
      info: {},
      runInfo: {
        day: 0,
        hour: 0,
        min: 0,
        sec: 0,
      },
      runTime: 0,
      articleList: [],
      sortStatus: 0,
      /**
       * 获取数据
       */
      getData(){
        RightData({

        }).then(res =>{
          data.info = res.data;
          data.runTime = res.data.opTime;
          data.countDown();
        })
      },
      /**
       * 获取文章数据
       */
      getArticle(){
        ArticleFiltrate({
          sortStatus: data.sortStatus
        }).then(res =>{
          data.articleList = res.data;
        })
      },
      /**
       * 倒计时
       */
      countDown(){
        setTimeout(() =>{
          let day = Math.floor(data.runTime/ (60*60*24));
          let hour = Math.floor(data.runTime / (60*60)%24);
          let min = Math.floor(data.runTime / (60)%60);
          let sec = Math.floor(data.runTime %60);
          data.runTime++
          data.runInfo.day = day;
          data.runInfo.hour = hour;
          data.runInfo.min = min;
          data.runInfo.sec = sec;
          data.countDown();
        },1000)
      },
      /**
       * 文章tab点击
       */
      onArticleTab(){
        data.getArticle();
      }
    })
    onMounted(() =>{
     data.parseTime = proxy.util.parseTime;
     data.getData();
     data.getArticle();
    })
    return {
      ...toRefs(data),
    }
  }
}
</script>

<style scoped lang="scss">
@import "PageRight.scss";
</style>
