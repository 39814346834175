<template>
  <div class="page">
    <!--  导航  -->
    <nav-bar PitchOn="首页"></nav-bar>
<!--    banner-->
    <div class="banner">
      <el-image style="width: 100%; height: 100%;" :src="homeImg"></el-image>
    </div>
<!--    主体-->
    <div class="main-info">
      <div class="info-left">
        <div class="article-list">
          <transition-group
              name="more"
              v-bind:css="false"
              v-on:before-enter="beforeEnter"
              v-on:enter="enter">
            <div class="list" v-for="(item,index) in articleList"
                 :data-index="index"
                 @click="onArticle(item)"
                 :key="index">
              <div class="picture">
                <el-image class="el-img" :src="item.img"></el-image>
                <div class="tag">
                  <span v-if="item.is_top === 1">置顶</span>
                </div>
              </div>
              <div class="item">
                <div class="title">
                  <span>{{item.title}}</span>
                </div>
                <div class="desc">
                  <span>{{item.describe}}</span>
                </div>
                <div class="date-view">
                  <div class="view">
                    <span class="iconfont icon-shijian"></span>
                    <span>{{parseTime(item.create_time,'{y}-{m}-{d}')}}</span>
                  </div>
                  <div class="view">
                    <span class="iconfont icon-liulan"></span>
                    <span>{{item.page_view}}人观看</span>
                  </div>
                  <div class="view">
                    <span class="iconfont icon-pinglun"></span>
                    <span>{{item.comment_num}}人吐槽</span>
                  </div>
                </div>
              </div>
            </div>
          </transition-group>
        </div>
        <el-empty description="无数据" v-show="articleList.length === 0"></el-empty>
      </div>
      <div class="info-right">
        <page-right></page-right>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "../../components/NavBar/NavBar";
import {defineComponent, getCurrentInstance, onMounted, reactive, toRefs} from 'vue'
import PageRight from "../../components/PageRight/PageRight";
import {homeData} from "../../api/api";
import { useRouter } from 'vue-router'
export default defineComponent({
  name: "home",
  components: {PageRight, NavBar},
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter()
    const data = reactive({
      show: false,
      articleList: [],
      homeImg: '',
      // 时间格式化
      parseTime: proxy.util.parseTime,
      getData(){
        homeData({

        }).then(res =>{
          data.articleList = res.data.articleList;
          data.homeImg = res.data.homeImg;
        })
      },
      /**
       * 文章点击
       * @param item
       */
      onArticle(item){
        router.push({path: '/ArticleDetails',query: {article_id: item.article_id}})
      },
      beforeEnter (el) {
        el.style.opacity = 0
      },
      enter (el, done) {
        let delay = el.dataset.index * 100
        setTimeout(()=>{
          el.style.transition = 'opacity 0.4s '
          el.style.opacity = 1
          el.style.animation = 'follow 0.4s infinite'
          el.style['animation-iteration-count'] = 1
          done()
        }, delay)
      }
    })
    onMounted(() => {
      data.show = !data.show;
      data.getData();
    })
    return {
      ...toRefs(data),
    }
  },
})
</script>

<style scoped lang="scss">
  @import "home.scss";
</style>
